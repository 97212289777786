
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class CarouselSportsMedalCard extends Vue {
        // Properties
        @Prop() private readonly medalImage!: string;

        @Prop() private readonly competition!: string;
        @Prop() private readonly competitionStyle!: string;
        @Prop() private readonly result!: string;
        @Prop() private readonly hostedAt!: string;
        @Prop() private readonly date!: string;
        @Prop() private readonly cardNumber!: string;

        // Data function
        public data() {
            return {
            };
        }
    }
