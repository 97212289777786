
    import CarouselSportsMedalCard from "@/components/carousel_cards/carousel_sports_medal_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSportsMedalCard
        },
    })

    // *****************************************************************************************************************
    export default class SportsMedals extends Vue {
        // Data function
        public data() {
            return {
                medal02ImagePathAndName:
                    require("@/assets/images/medal_02.png"),
                medal03ImagePathAndName:
                    require("@/assets/images/medal_03.png"),
                medal04ImagePathAndName:
                    require("@/assets/images/medal_04.png"),
                medal05ImagePathAndName:
                    require("@/assets/images/medal_05.png"),
                medal06ImagePathAndName:
                    require("@/assets/images/medal_06.png"),
                medal07ImagePathAndName:
                    require("@/assets/images/medal_07.png"),
                medal08ImagePathAndName:
                    require("@/assets/images/medal_08.png"),
                medal09ImagePathAndName:
                    require("@/assets/images/medal_09.png"),
                medal10ImagePathAndName:
                    require("@/assets/images/medal_10.png"),
                medal11ImagePathAndName:
                    require("@/assets/images/medal_11.png"),
                medal12ImagePathAndName:
                    require("@/assets/images/medal_12.png"),
                medal13ImagePathAndName:
                    require("@/assets/images/medal_13.png"),
                medal14ImagePathAndName:
                    require("@/assets/images/medal_14.png"),
                medal15ImagePathAndName:
                    require("@/assets/images/medal_15.png"),
                medal16ImagePathAndName:
                    require("@/assets/images/medal_16.png"),
                medal17ImagePathAndName:
                    require("@/assets/images/medal_17.png"),
                medal18ImagePathAndName:
                    require("@/assets/images/medal_18.png"),

                wrestlingIconPathAndName:
                    require("@/assets/images/icon_wrestling.png"),
            };
        }
    }
