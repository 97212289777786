
    import SportsMedals from "@/components/sports_medals.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            SportsMedals
        },
    })

    // *****************************************************************************************************************
    export default class AboutMySports extends Vue {
        // Data function
        public data() {
            return {
                wrestlingIconPathAndName:
                    require("@/assets/images/icon_wrestling.png"),

                wrestlingImage01PathAndName:
                    require("@/assets/images/wrestling_01.png"),
                wrestlingImage02PathAndName:
                    require("@/assets/images/wrestling_02.png"),
                wrestlingImage03PathAndName:
                    require("@/assets/images/wrestling_03.png"),
                wrestlingImage04PathAndName:
                    require("@/assets/images/wrestling_04.png"),
                wrestlingImage05PathAndName:
                    require("@/assets/images/wrestling_05.png"),
                wrestlingImage06PathAndName:
                    require("@/assets/images/wrestling_06.png"),
            };
        }
    }
